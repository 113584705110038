import React from "react";
import rays_so from "../Assets/Images/ray-so-export.png";
import FinanceAnimation from "../Assets/Lottie/finance_animation.json";
import Lottie from "react-lottie-player";

function Screen3() {
  return (
    <div
      style={
        {
          // backgroundColor: "#2a1111",
          // height: "100vh",
        }
      }
      className="Screen3background container-main"
    >
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <p
          style={{
            color: "#f8e16c",
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          BetaCode
        </p>
        <p
          style={{
            color: "#f8e16c",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          BetaCode
        </p>
      </div> */}
      <div
        style={
          {
            // display: "flex",
            // justifyContent: "space-between",
            // alignItems: "center",
            // padding: 20,
          }
        }
        className="container-text-img"
      >
        <div>
          <p
            style={{
              // color: "#FFC2B4",
              fontSize: 80,
              fontWeight: "bold",
            }}
            className="screen-3-gradient text-width"
          >
            Finance App Designed for Financial Agents
          </p>
          <p
            style={{
              color: "#c39680",
              fontSize: 28,
            }}
            className="text-width"
          >
            Provides a secure platform for uploading, storing, and downloading
            Know Your Customer (KYC) documents. Transaction Tracking: Allows
            agents to record and monitor all financial transactions
          </p>
        </div>

        {/* <img src={rays_so} className="App-logo" alt="logo" /> */}
        <Lottie
          loop
          animationData={FinanceAnimation}
          play
          style={{
            width: "100%",
            height: "50vh",
          }}
        />
      </div>
    </div>
  );
}

export default Screen3;
