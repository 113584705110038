import logo from "./logo.svg";
import "./App.css";

import Navbar from "./Components/Navbar";
import Screen1 from "./Screens/Screen1";
import Screen2 from "./Screens/Screen2";
import Screen3 from "./Screens/Screen3";
import Footer from "./Screens/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
     <Screen1/>
     <Screen2/>
     <Screen3/>
     <Footer/>
    </div>
  );
}

export default App;
