import React from "react";
import rays_so from "../Assets/Images/ray-so-export.png";

function Screen2() {
  return (
    <div
      style={{
        backgroundColor: "#2a1111",
      }}
      className="container-main"
    >
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <p
          style={{
            color: "#f8e16c",
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          BetaCode
        </p>
        <p
          style={{
            color: "#f8e16c",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          BetaCode
        </p>
      </div> */}
      <div
        style={
          {
            // display: "flex",
            // justifyContent: "space-between",
            // alignItems: "center",
            // padding: 20,
          }
        }
        className="container-text-img"
      >
        <div>
          <p
            style={{
              // color: "#FFC2B4",
              fontSize: 80,
              fontWeight: "bold",
              // width:"90%"
            }}
            className="text-gradient text-width"
          >
            Manage Your Finance with the Tech
          </p>
          <p
            style={{
              color: "#c39680",
              fontSize: 28,
            }}
            className="text-width"
          >
            Transform your financial agent's experience with seamless client
            data management and transaction tracking!
          </p>
        </div>

        <img src={rays_so} className="App-logo" alt="logo" />
      </div>
    </div>
  );
}

export default Screen2;
