import React from 'react'
import "./Footer.css"
import InstagramIcon from "../Assets/Images/instagram.png"
import BetaCodeLogo from "../Assets/Images/BetaCodeLogo.png";


function Footer() {
       const openURLInNewTab = (url) => {
         window.open(url, "_blank", "noreferrer");
       };
  return (
    <section id="footer">
      <div className="main-footer">
        <div className="logoinfo" data-aos="fade-up">
          <h2>Accountant</h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              className="designPowered"
              style={{
                color: "#ae77e6",
              }}
            >
              Design and powered by{" "}
            </p>
            <img
              src={BetaCodeLogo}
              className="icons-style"
              style={{
                height: 60,
                width: 60,
              }}
            />
          </div>
          <div className="contact-details">
            <h1>Contact Us</h1>
            <li>
              <div className="fa fa-phone" />
              <p
                style={{
                  color: "#f1f1f1",
                  cursor: "pointer",
                }}
                onClick={() => {
                  openURLInNewTab("https://pdhanewala.com");
                }}
              >
                www.pdhanewala.com
              </p>
            </li>
            <li>
              <div className="fa fa-envelope" />
              <p
                style={{
                  color: "#f1f1f1",
                  cursor: "pointer",
                }}
                onClick={() => {
                  openURLInNewTab("betacode.infotech@gmail.com");
                }}
              >
                betacode.infotech@gmail.com
              </p>
            </li>
          </div>
        </div>
        <div className="com" data-aos="fade-up">
          <h1>About BetaCode</h1>
          <p
            style={{
              maxWidth: 200,
              color: "#fff",
            }}
          >
            Betacode is Noida based startup. BetaCode pioneers in website and
            app building for various domains. Our goal is to lead and deliver
            revolutionary firsts, drive Innovation and ingenuity in the field of
            website and App building.
          </p>
          {/* <p className="aboutBetaCode">
            Betacode is Noida based startup. BetaCode pioneers
            in website and app building for various domains. Our goal is to lead
            and deliver revolutionary firsts, drive Innovation and ingenuity in
            the field of website and App building.
          </p> */}
          {/* <ul>
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#">About</a>
            </li>
            <li>
              <a href="#">Projects</a>
            </li>
            <li>
              <a href="#">Contact</a>
            </li>
          </ul> */}
        </div>
        <div className="info" data-aos="fade-up">
          <h1>Follow us</h1>
          <div className="sociallogos">
            <div className="logobox" style={{
              display:"flex",
              justifyContent:"center"
            }}>
              <img
                src={InstagramIcon}
                className="icons-style"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  openURLInNewTab(
                    "https://www.instagram.com/betacodeinfotech/"
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <footer>© BetaCode Copyright 2023 All Rights Reserved</footer>
    </section>
  );
}

export default Footer