import React from 'react'
import Three_Emulator from "../Assets/Images/three-phone.png";

function Screen1() {
  return (
    <div
      style={{
        backgroundColor: "#000",
        // height:"100vh"
      }}
      className="container-main screen1-style"
    >
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <p
          style={{
            color: "#f8e16c",
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          BetaCode
        </p>
        <p
          style={{
            color: "#f8e16c",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          BetaCode
        </p>
      </div> */}
      <div
        style={
          {
            // display: "flex",
            // justifyContent: "space-between",
          }
        }
        className="container-text-img"
      >
        <div>
          <p
            style={{
              // color: "#FFC2B4",
              // fontSize: 80,
              fontWeight: "bold",
              // maxWidth: "100%",
              // width: "100%",
            }}
            className="text-gradient text-width screen1Font"
          >
            Unleash the Power of Innovation
          </p>
          <p
            style={{
              color: "#ae77e6",
              fontSize: 28,
              // width: "100%",
            }}
            className='text-width'
          >
            Ready to streamline your financial business? Try Accountant today
          </p>
        </div>

        <img src={Three_Emulator} className="App-logo" alt="logo" />
      </div>
    </div>
  );
}

export default Screen1