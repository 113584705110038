import React from "react";
// import Toggle from "../Toggle/Toggle";
import "./Navbar.css";
import { Link } from "react-scroll";

// import useWindowSize from "../../Components/HOC/useWindowSize";
import AccountantLogo from "../Assets/Images/appstore.png";
const Navbar = () => {
      const openURLInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
      };
  return (
    <div className="n-wrapper" id="Navbar">
      {/* left */}
      <div className="n-left" style={{
        marginLeft:5  
      }}>
        <img
          src={AccountantLogo}
          className="logo"
          style={{
            width: 40,
            height: 40,
          }}
        />
        <div className="n-name" style={{}}>
          Accountant
        </div>
        {/* <Toggle /> */}
      </div>
      {/* right */}
      <div className="n-right">
        <div className="n-list">
          {/* <ul style={{ listStyleType: "none" }}>
            <li>
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="whyus"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                onClick={() => {
                  //   scrollTo(height * 2);
                }}
                // onSetActive={handleSetActive}
              >
                Why us?
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="features"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                onClick={() => {
                  //   scrollTo(height * 2);
                }}
                // onSetActive={handleSetActive}
              >
                Features
              </Link>
            </li>
            <li>
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                About
              </Link>
            </li>
        
          </ul> */}
        </div>
        <div style={{
          marginRight:5
        }}>
          <Link to="contact" spy={true} smooth={true}>
            <button className="GlowingButton" onClick={()=>{
                openURLInNewTab(
                  "https://play.google.com/store/apps/details?id=com.Accountant"
                );
            }} >Download App</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
